import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { useMaterialUIController } from 'context';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import CalendarRoot from 'examples/Calendar/CalendarRoot';

const DashboardCalendar = () => {
  const [calendarEventsData, setCalendarEventsData] = useState({
    data: [],
  });
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const calendarEvents = useSelector((state) => state.dashboard.calendarEvents);
  const calendarCategories = useSelector(
    (state) => state.dashboard.calendarCategories
  );

  useEffect(() => {
    if (calendarEvents) {
      fetchCalendar();
    }
  }, [calendarEvents]);

  const handleEvents = (arg) => {
    console.log('handleEvents');
  };

  const handleClick = () => {
    console.log('handleClick');
  };

  const fetchCalendar = () => {
    const getCategoryColor = (categoryId) => {
      const getColor = calendarCategories?.find(
        (item) => item.categoryId === categoryId
      );
      return getColor?.color;
    };
    const eventsData = calendarEvents?.map((item, index) => ({
      ...item,
      id: item.eventId,
      title: item.title,
      description: item.description,
      start: item.startDate,
      end: moment(new Date(item.endDate)).add(1, 'day').startOf('day').toDate(),
      startTime: item.startDate,
      endTime: item.endDate,
      allDay: item.isAllDayEvent,
      location: item.location,
      categoryId: item.categoryId,
      color: getCategoryColor(item.categoryId),
    }));
    setCalendarEventsData(() => ({
      data: eventsData,
    }));
  };

  return (
    <>
      <CalendarRoot p={2} ownerState={{ darkMode }}>
        <FullCalendar
          headerToolbar={{
            left: 'title',
            center: '',
            right: 'prev next',
          }}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          events={calendarEventsData?.data}
          displayEventTime={false}
          datesSet={(arg) => {
            handleEvents(arg);
          }}
          selectable={true}
          select={handleClick}
          initialView="dayGridMonth"
          height="100%"
        />
      </CalendarRoot>
    </>
  );
};

export default DashboardCalendar;
