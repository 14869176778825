import { createSlice } from '@reduxjs/toolkit';

const userDetailsSlice = createSlice({
  name: 'deltaSnp/userDetails',
  initialState: null,
  reducers: {
    getUserDetails: (state, action) => action.payload,
    resetUserDetails: () => null,
  },
});

export const { getUserDetails, resetUserDetails } = userDetailsSlice.actions;

export default userDetailsSlice.reducer;
