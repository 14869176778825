import { styled } from '@mui/material/styles';
import MDBox from 'components/MDBox';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import MDTypography from 'components/MDTypography';

export const DashboardLeftGrid = styled(Grid)`
  justify-content: space-between;
`;

export const AnnouncementCard = styled(Card)`
  max-height: 20vh;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 9px;
  }
  ::-webkit-scrollbar-thumb {
    background: #dcdcdc;
    border-radius: 10px;
  }
`;

export const HeadingTypography = styled(Typography)`
  padding: 16px 16px 0px 16px;
`;

export const AnnouncementsWrapper = styled(MDBox)`
  display: flex;
  align-items: flex-start;
  padding: 8px;
  line-height: 1;
`;

export const TitleTypography = styled(MDTypography)`
  margin-left: 8px;
  font-size: 14px;
  font-weight: 400;
`;

export const DashboardSubHeader = styled(Box)`
  display: flex;
  z-index: 9;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  top: 0px;
  position: sticky;
  background-color: #42424a;
  border-radius: 10px 10px 0px 0px;
`;

export const SubHeadingTypography = styled(Typography)`
  color: #ffffff;
`;

export const DashboardCard = styled(Card)`
  position: sticky;
  height: 32vh;
  max-height: 32vh;
  overflow-y: scroll;
  border-radius: 0px 0px 10px 10px;
  ::-webkit-scrollbar {
    width: 9px;
  }
  ::-webkit-scrollbar-thumb {
    background: #dcdcdc;
    border-radius: 10px;
  }
`;

export const TypographyTable = styled(MDTypography)`
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 400;
`;

export const MyClassGrid = styled(Grid)`
  padding: 16px;
  //height: 30vh;
`;

export const Loader = styled(Box)`
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LinkWrapper = styled(MDBox)`
  display: flex;
  align-items: flex-start;
  padding: ${(props) => `${props.theme.palette.spacing[1] * 1}px `};
  line-height: 1;
  &:hover {
    background-color: ${(props) => props.theme.palette.background.default};
    border-radius: 8px;
  }
`;

export const LinkWrapperGrid = styled(Grid)`
  margin: ${(props) =>
    `${props.theme.palette.spacing[1] * 1}px ${
      props.theme.palette.spacing[1] * 0
    }px ${props.theme.palette.spacing[1] * 0}px ${
      props.theme.palette.spacing[1] * 1
    }px`};
`;
