import React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { useSelector } from 'react-redux';
import {
  TypographyTable,
  HeadingTypography,
  MyClassGrid,
} from 'components/Ui/styled';

const DashboardMyClasses = () => {
  const courses = useSelector((state) => state.dashboard.courses);
  return (
    <Card>
      <HeadingTypography variant="h6">My Classes</HeadingTypography>
      <MyClassGrid container spacing={1}>
        <Grid item xs={12} sm={5}>
          {courses?.map((item, index) => (
            <TypographyTable style={{ fontWeight: 500 }} key={index}>
              {item.courseName}
            </TypographyTable>
          ))}
        </Grid>
        <Grid item xs={12} sm={5}>
          {courses?.map((item, index) => (
            <TypographyTable key={index}>{item.instructor}</TypographyTable>
          ))}
        </Grid>
        <Grid item xs={12} sm={2}>
          {courses?.map((item, index) => (
            <TypographyTable key={index}>{item.currentGrade}</TypographyTable>
          ))}
        </Grid>
      </MyClassGrid>
    </Card>
  );
};

export default DashboardMyClasses;
