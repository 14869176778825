import React from 'react';
import Link from '@mui/material/Link';
import { useSelector } from 'react-redux';
import { LinkItems } from 'utils/commonUtils';
import { LinkWrapperGrid } from 'components/Ui/styled';

const DashboardLaunch = () => {
  const studentLinks = useSelector((state) => state.dashboard.studentLinks);
  return (
    <>
      {studentLinks?.map((item, index) => {
        return (
          <Link href={item.url} target="_blank" key={index}>
            <LinkWrapperGrid>
              <LinkItems title={item.title} />
            </LinkWrapperGrid>
          </Link>
        );
      })}
    </>
  );
};

export default DashboardLaunch;
