import React, { useState, useEffect } from 'react';
import MDBox from 'components/MDBox';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { showLoader } from 'utils/commonUtils';
import { useDispatch } from 'react-redux';
import { getDashboard } from './services/dashboardApi';
import { getDashboardDetails } from '../store/dashboardSlice';
import DashboardLaunch from './components/dashboardLaunch/DashboardLaunch';
import DashboardCalendar from './components/dashboardCalendar/DashboardCalendar';
import DashboardMyClasses from './components/dashboardMyClasses/DashboardMyClasses';
import DashboardAnnouncements from './components/dashboardAnnouncements/DashboardAnnouncements';
import DashboardUpcomingDueDates from './components/dashboardUpcomingDueDates/DashboardUpcomingDueDates';
import {
  DashboardCard,
  AnnouncementCard,
  HeadingTypography,
  DashboardLeftGrid,
  DashboardSubHeader,
  SubHeadingTypography,
} from 'components/Ui/styled';

const MainDashboard = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchDashboard();
  }, []);

  const fetchDashboard = async () => {
    const dashboardData = await getDashboard();
    dispatch(getDashboardDetails(dashboardData));
    setIsLoading(false);
  };

  return (
    <>
      {isLoading ? (
        showLoader()
      ) : (
        <>
          <Grid container spacing={3}>
            <DashboardLeftGrid item xs={12} sm={6}>
              <AnnouncementCard>
                <HeadingTypography variant="h6">
                  Announcements
                </HeadingTypography>
                <DashboardAnnouncements />
              </AnnouncementCard>
              <MDBox pt={3.5}></MDBox>
              <DashboardMyClasses />
              <MDBox pt={3.5}></MDBox>
              <DashboardSubHeader>
              <SubHeadingTypography variant="h6">Launch</SubHeadingTypography>
              </DashboardSubHeader>
              <DashboardCard>
                <DashboardLaunch />
              </DashboardCard>

            </DashboardLeftGrid>
            <Grid item xs={12} sm={6}>
              <Card>
                <HeadingTypography variant="h6">My Calendar</HeadingTypography>
                <DashboardCalendar />
              </Card>
            </Grid>
          </Grid>
          
          <MDBox pt={3.5}></MDBox>
          <Grid container spacing={3}>
            {/*
            <DashboardLeftGrid item xs={12} sm={6}>
              <DashboardSubHeader>
                <SubHeadingTypography variant="h6">
                  Upcoming due dates
                </SubHeadingTypography>
              </DashboardSubHeader>
              <DashboardCard>
                <DashboardUpcomingDueDates />
              </DashboardCard>
            </DashboardLeftGrid>
            <Grid item xs={12} sm={12}>
              <DashboardSubHeader>
                <SubHeadingTypography variant="h6">Launch</SubHeadingTypography>
              </DashboardSubHeader>
              <DashboardCard>
                <DashboardLaunch />
              </DashboardCard>
            </Grid>
            */}
          </Grid>
        </>
      )}
    </>
  );
};

export default MainDashboard;
