import React from 'react';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import { ListItems } from 'utils/commonUtils';

const DashboardAnnouncements = () => {
  const announcements = useSelector((state) => state.dashboard.announcements);
  return (
    <>
      {announcements?.map((item, index) => {
        return (
          <Box key={index}>
            <ListItems title={item.msg} />
          </Box>
        );
      })}
    </>
  );
};

export default DashboardAnnouncements;
