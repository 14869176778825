import { createSlice } from '@reduxjs/toolkit';

const dashboardSlice = createSlice({
  name: 'deltaSnp/dashboard',
  initialState: null,
  reducers: {
    getDashboardDetails: (state, action) => action.payload,
    resetDashboardDetails: () => null,
  },
});

export const { getDashboardDetails, resetDashboardDetails } =
  dashboardSlice.actions;

export default dashboardSlice.reducer;
