import React from 'react';
import Box from '@mui/material/Box';

const GetHelpComponent = () => {
  return (
    <>
      <Box p={4}>
        General Questions? Please email <a href="mailto:jesse.fox@deltaacademylv.com">
                                          jesse.fox@deltaacademylv.com
                                        </a>
        <Box pt={3}>
          Questions about Classes?  Email your counselor:
        </Box>  
        <Box pl={5}>
          <ul>
            <li>
              Grades 6-8: <a href="mailto:shiela.burns@deltaacademylv.com">
                            shiela.burns@deltaacademylv.com
                          </a>  
            </li>  
            <li>
              Grades 9-10: <a href="mailto:kima.mkhitarian@deltaacademylv.com">
                            kima.mkhitarian@deltaacademylv.com
                          </a>
            </li>  
            <li>
              Grades 11-12: <a href="mailto:jennifer.cann@deltaacademylv.com">
                              jennifer.cann@deltaacademylv.com
                            </a>
            </li>  
          </ul>
        </Box>  
        <Box pt={3}>
          Need to talk to someone?  Set up an appointment with one of our school social workers:
        </Box>  
        <Box pl={5}>
          <ul>
            <li>
              Ms. Akazawa: <a href="mailto:kianna.akazawa@deltaacademylv.com">
                            kianna.akazawa@deltaacademylv.com
                          </a>  
            </li>  
            <li>
              Ms. Dominguez: <a href="mailto:elizah.dominguez@deltaacademylv.com">
                            elizah.dominguez@deltaacademylv.com
                          </a>
            </li>  
            <li>
              Mr. Lujan:  <a href="ray.lujan@deltaacademylv.com">
                              ray.lujan@deltaacademylv.com
                            </a>
            </li>  
          </ul>
        </Box>  
        <Box pt={3}>
          Want to Zoom with a teacher? Here are the links:
        </Box>  
        <Box pl={2}>
          General questions, help and test taking: 
        </Box>  
        <Box pl={2}>
          Mondays, Tuesdays, Thursdays, and Fridays from 8:00 AM - 3:00 PM and Wednesdays from 8:00 AM - 2:30 PM
        </Box>  
        <Box pl={5}>
          <ul>
            <li>
              Mrs. Lori McCarty - <a href="https://zoom.us/j/8331295548">https://zoom.us/j/8331295548</a>
            </li>  
            <li>
              Student Success Advocates: - <a href="https://zoom.us/j/6287492310">https://zoom.us/j/6287492310</a>
            </li>  
          </ul>
        </Box>
        <Box pl={2} pt={2}>
          Specific Subject area Zooms will be open Monday - Friday from 8:00 AM - 11:30 AM 
        </Box>  
        <Box pl={5}>
          <ul>
            <li>
              English (Mr. McLerran) - <a href="https://us06web.zoom.us/j/4456893755">https://us06web.zoom.us/j/4456893755</a>
            </li>  
            <li>
              Math (Mr. Mancuso) - <a href="https://us06web.zoom.us/j/9691572663">https://us06web.zoom.us/j/9691572663</a>
            </li>  
            <li>
              Science (Ms. South) - <a href="https://us06web.zoom.us/j/8965780848">https://us06web.zoom.us/j/8965780848</a>
            </li>  
            <li>
              Social Studies and Electives (Mr. Pennington) - <a href="https://zoom.us/j/5904621714">https://zoom.us/j/5904621714</a>
            </li>  
          </ul>
        </Box>
      </Box>
    </>
  );
};

export default GetHelpComponent;
