import MDTypography from 'components/MDTypography';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import {
  Loader,
  AnnouncementsWrapper,
  LinkWrapper,
  TitleTypography,
} from 'components/Ui/styled';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
import LoadingSpinner from 'components/Loader/LoadingSpinner';

export const renderComponent = (component) => {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {component}
    </DashboardLayout>
  );
};

export const ListItems = (props) => {
  const { title } = props;
  return (
    <AnnouncementsWrapper>
      <MDTypography variant="body1" color="secondary" lineHeight={0.75}>
        <FiberManualRecordRoundedIcon />
      </MDTypography>
      <TitleTypography>{title}</TitleTypography>
    </AnnouncementsWrapper>
  );
};

export const showLoader = () => (
  <Loader>
    <LoadingSpinner />
  </Loader>
);

export const LinkItems = (props) => {
  const { title } = props;
  return (
    <LinkWrapper>
      <MDTypography variant="body1" color="secondary" lineHeight={0.75}>
        <FiberManualRecordRoundedIcon />
      </MDTypography>
      <TitleTypography>{title}</TitleTypography>
    </LinkWrapper>
  );
};
