import { Image, Heading, useTheme } from "@aws-amplify/ui-react";
import logo from "../../assets/images/deltaSnp/deltaStudentParentPortalLogo.png";
import Box from '@mui/material/Box';

export function Header() {
  const { tokens } = useTheme();

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        >
          <Image
            alt="logo"
            height="40%"
            style={{ alignSelf: 'center' }}
            width="40%"
            //paddingLeft="140px"
            src={logo}
            padding={tokens.space.medium}
          />
      </Box>  
      <Heading textAlign="center" level={4}>
        Sign in to your Account
      </Heading>
    </>  
  );
}
